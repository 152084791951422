.PlayerContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.VPPlayer {
    padding: 1.5em;
    border-radius: 6px;
    background-color: rgba(6,83,124,0.7);
    width: 100vh;
    max-width: 100vmin;
}

.VPPlayer-video {
    position: relative;
}

.VPPlayer-video::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: calc(31vh * (16/9));
}

.VPVideo {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.VPVideo video {
    width: 100%;
}

.VPControls {
    margin-top: 1em;
    color: white;
}

.VPControls-progress {
    display: flex;
    align-content: flex-start;
    align-items: center;
}

.VPControls-time {
    display: inline-block;
    font-size: 16px;
    font-size: 1.6rem;
    padding-right: 1em;
}

.VPControls-progressBar {
    display: inline-block;
    flex-grow: 2;
}

.VPControls-controls {
    display: flex;
    justify-content: space-between;
    padding-top: 1em;
}

.VPControls-rangeInput {
    width: 100%;
    background-color: transparent;
}

.VPControls-rangeInput {
    -webkit-appearance: none;
    width: 100%;
    margin: 5.15px 0;
}

.VPControls-rangeInput:focus {
    outline: none;
}

.VPControls-rangeInput::-moz-focus-outer {
    border: 0;
}

.VPControls-rangeInput::-webkit-slider-runnable-track {
    width: 100%;
    height: 5.7px;
    cursor: pointer;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    background: #0C9CE5;
    border-radius: 25px;
    border: 0px solid #010101;
}

.VPControls-rangeInput::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    border: 3px solid #0C9CE5;
    height: 16px;
    width: 16px;
    border-radius: 50px;
    background: #ffffff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -5.15px;
}

.VPControls-rangeInput:focus::-webkit-slider-runnable-track {
    background: #0C9CE5;
}

.VPControls-rangeInput::-moz-range-track {
    width: 100%;
    height: 5.7px;
    cursor: pointer;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    background: #0C9CE5;
    border-radius: 25px;
    border: 0px solid #010101;
}

.VPControls-rangeInput::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    border: 3px solid #0C9CE5;
    height: 16px;
    width: 16px;
    border-radius: 50px;
    background: #ffffff;
    cursor: pointer;
    box-sizing: border-box;
}

.VPControls-rangeInput::-ms-track {
    width: 100%;
    height: 5.7px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
}

.VPControls-rangeInput::-ms-fill-lower {
    background: #0C9CE5;
    border: 0px solid #010101;
    border-radius: 50px;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
}

.VPControls-rangeInput::-ms-fill-upper {
    background: #0C9CE5;
    border: 0px solid #010101;
    border-radius: 50px;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
}

.VPControls-rangeInput::-ms-thumb {
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    border: 3px solid #0C9CE5;
    height: 16px;
    width: 16px;
    border-radius: 50px;
    background: #ffffff;
    cursor: pointer;
    height: 5.7px;
}

.VPControls-rangeInput:focus::-ms-fill-lower {
    background: #0C9CE5;
}

.VPControls-rangeInput:focus::-ms-fill-upper {
    background: #0C9CE5;
}

.VPControls-playPause {
    width: 4rem;
    height: 4rem;
    cursor: pointer;
}

.VPControls-volume {
    width: 9rem;
    display: flex;
    align-items: center;
}

.VPControls-volumeIcon {
    width: 4rem;
    padding-right: 0.5rem;
    color: #BBB;
}

.VPControls-volumeIcon.canMute {
    color: #FFF;
    cursor: pointer;
}

.VPIcons {
    width: 100%;
    height: 100%;
}
